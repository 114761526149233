<script lang="ts">
	import { createEventDispatcher } from 'svelte';
	import FoldoutBox from './FoldoutBox.svelte';
	import type { WidgetState } from './types';

	export let state: WidgetState | undefined;
	export let email: string | undefined;
	export let terms: boolean = false;
	export let showTerms: boolean = false;
	export let showError: boolean = false;
	export let className: string = '';

	const dispatcher = createEventDispatcher();

	function signup() {
		dispatcher('signup', { email: email?.trim(), terms });
	}

	function back() {
		dispatcher('back', {});
	}

	function focus() {
		dispatcher('back', {});
	}
</script>

<div class="registration-widget {className}">
	<div class="success-container sliding" class:visible={state === 'submitted'}>
		<div class="arrow">
			<svg
				width="23"
				height="15"
				viewBox="0 0 23 15"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_1855_1676)">
					<path
						d="M0.0449489 13.6114C0.432116 13.6857 0.520282 13.365 0.654449 13.1186C1.32145 11.8944 2.39478 11.3389 3.70578 11.249C4.62578 11.1864 5.55345 11.1747 6.47345 11.2216C7.29762 11.2646 7.53145 10.9126 7.46628 10.1264C7.17112 6.61013 6.15528 3.38327 3.90895 0.629686C3.77478 0.465409 3.56395 0.320689 3.62912 0.0116933C4.50312 -0.082179 5.30812 0.258108 6.11695 0.504523C11.5641 2.17467 15.8689 5.34285 18.6098 10.5254C18.8704 11.0182 19.1886 11.2255 19.7368 11.2177C20.8178 11.202 21.8988 11.2451 22.9798 11.2646C22.9798 12.2698 22.9453 13.2751 22.9913 14.2764C23.0181 14.8239 22.9261 14.996 22.3243 14.9882C18.7669 14.9491 15.2134 14.9687 11.6561 14.9687C8.09878 14.9687 4.54528 14.9374 0.987949 14.9921C0.167615 15.0039 -0.119884 14.8005 0.0372822 13.9908C0.0602822 13.8657 0.0372822 13.7327 0.0372822 13.6036L0.0449489 13.6114Z"
						fill="#F4F4F4"
					/>
				</g>
				<defs>
					<clipPath id="clip0_1855_1676">
						<rect width="23" height="15" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</div>
		<div class="d-flex">
			<div>
				<svg
					width="14"
					height="14"
					viewBox="0 0 14 14"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="7" cy="7" r="6" stroke="#267256" />
					<path
						d="M5.2168 7.52461L6.40129 8.78375L9.75734 5.21619"
						stroke="#267256"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</div>

			<div class="right-content">
				<h5>Please check your email!</h5>

				<div class="content">
					<p>We’ve sent you instructions on how to continue.</p>
				</div>
			</div>
		</div>
	</div>

	<div
		class="form-container sliding"
		class:visible={state !== 'submitted' && state !== 'loading'}
		class:error={state !== 'default'}
	>

		<div class="email-row">
			<div class="inputwrapper">
				<div class="signup-notice">
					Work email address <b>(required)</b>
				</div>
				<input
					bind:value={email}
					placeholder={className === 'new-style'
						? 'name@email.com'
						: 'name@company.com'}
					on:focus={focus}
				/>
			</div>

			<div class="buttonwrapper">
				{#if state !== 'default' && state !== 'submitted'}
					<button on:click={back}> Back </button>
				{:else}
					<div class="signup-notice text-center md-hide">No card required</div>
					<button on:click={signup}>
						{className === 'new-style' ? 'SIGN UP FOR FREE' : 'Try for free'}
					</button>
				{/if}
			</div>
		</div>
		<div class="signup-notice md-show text-center"><b>No credit card required</b></div>

		<div class="foldout-container">
			<FoldoutBox error visible={showError} className="msgb{className}">
				<svg
					width="14"
					height="14"
					viewBox="0 0 14 14"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M4.59961 4.59863L9.40216 9.40118"
						stroke="#AD1C3B"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M9.40216 4.59863L4.59961 9.40118"
						stroke="#AD1C3B"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
						stroke="#AD1C3B"
						stroke-miterlimit="10"
					/>
					<path
						d="M4.59961 4.59863L9.40216 9.40118"
						stroke="#AD1C3B"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M9.40216 4.59863L4.59961 9.40118"
						stroke="#AD1C3B"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
						stroke="#AD1C3B"
						stroke-miterlimit="10"
					/>
				</svg>

				{#if state === 'private-email'}
					Please use your company email address to sign up
				{:else if state === 'terms-error'}
					You must agree to the&nbsp;
					<a href="https://www.lingio.com/en/terms-of-service-v0" target="_blank" rel="noreferrer"
						>terms and conditions</a
					> &nbsp;to continue
				{:else if state === 'email-already-exists'}
					It looks like you already have an account,&nbsp;
					<a href="https://portal.lingio.com?alreadyuser"> please log in </a>
				{:else if state === 'invalid-email'}
					This doesn't look like a valid email address
				{:else if state === 'blocked'}
					This service isn't available in your country
				{/if}
			</FoldoutBox>
			<FoldoutBox visible={showTerms} className="msgb{className}">
				<label>
					By signing up you accept our
					<a href="https://www.lingio.com/en/terms-of-service-v0" target="_blank" rel="noreferrer"
						>terms of use</a
					>
					&nbsp;and&nbsp;
					<a href="https://www.lingio.com/en/privacy-policy" target="_blank" rel="noreferrer"
						>policies</a
					>.
				</label>
			</FoldoutBox>
		</div>
	</div>
</div>

<style>
	input,
	button {
		outline-color: #6b2e61;
	}

	a {
		color: #6b2e61;
		outline-color: #6b2e61;
	}

	:host {
		display: block;
	}
	:host:focus {
		outline: none;
	}

	.d-flex {
		display: flex;
	}

	.text-center {
		text-align: center !important;
	}
	.foldout.error .arrow path {
		fill: #f4f4f4;
	}

	.arrow {
		position: absolute;
		left: 16px;
		top: -11px;
		width: 15px;
		height: 15px;
	}

	.registration-widget {
		display: block;
		height: 110px;
		width: auto;
		min-width: 200px;
		max-width: 500px;
		position: relative;
		box-sizing: border-box;
		margin: 10px 0;
		font-family: 'Manrope';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 25px;
	}

	.registration-widget p {
		margin: 0;
		padding: 0;
		flex: 1;
	}

	label {
		display: block;
	}

	label > input {
		margin-right: 5px;
	}

	.form-container {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	.form-container .email-row {
		display: flex;
		flex-direction: row;
		gap: 20px;
	}

	.form-container .email-row .inputwrapper {
		flex: 5;
		max-width: 270px;
	}

	.form-container .email-row .buttonwrapper {
		flex: 2;
		min-width: 160px;
		max-width: 195px;
	}

	.form-container .email-row input {
		font-family: 'Manrope';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		box-sizing: border-box;
		width: 100%;
		height: 50px;
		padding: 10px 24px;
		color: #6b2e61;
		border: 1px solid #6b2e61;
		border-radius: 1000px;
		box-sizing: border-box;
	}

	.form-container input[type='checkbox'] {
		cursor: pointer;
	}

	.form-container .email-row input::placeholder {
		color: #c8a2c2;
	}

	.form-container .email-row button {
		font-family: 'Noto Sans';
		font-style: normal;
		font-weight: 600;
		font-size: 16.413px;
		line-height: 16px;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		flex: 0 1 120px;
		/* min-width: 80px; */
		width: 100%;
		height: 50px;
		background: #6b2e61;
		border-radius: 100px;
		color: #fff;
		border: 0;
		cursor: pointer;
	}

	.form-container .email-row button:hover {
		opacity: 0.75;
	}

	.form-container.error {
		color: #ad1c3b;
	}

	.foldout-container {
		height: 60px;
		position: relative;
	}

	.foldout-container .error .content svg {
		margin-right: 5px;
	}

	.success-container {
		position: absolute;
		left: 0;
		top: 0;
		color: #267256;
		padding: 13px 19px;
		background-color: #f3f3f3;
		border-radius: 20px;
		box-shadow: none;
		font-size: 13px;
	}

	.success-container h5 {
		margin: 0;
		padding: 0;
		font-family: 'Manrope';
		font-style: normal;
		font-weight: 700;
		line-height: 18px;
		font-size: 13px;
	}
	.success-container .content {
		line-height: 1;
	}

	.right-content {
		margin-left: 7px;
	}

	.sliding {
		opacity: 0;
		transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
		transform: translateY(16px);
		pointer-events: none;
	}

	.sliding.visible {
		opacity: 1;
		transform: none;
		pointer-events: all;
		transition-delay: 100ms;
	}

	.signup-notice {
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		font-family: 'Manrope';
		color: #8a8a8a;
		margin-bottom: 10px;
	}

	.signup-page .form-container .email-row {
		flex-direction: column;
	}

	.new-style .form-container .email-row {
		flex-direction: column;
		gap: 20px;
	}

	.new-style .form-container .email-row .buttonwrapper {
		flex: 1;
		min-width: 100% !important;
		max-width: 100% !important;
	}

	.new-style .form-container .email-row .inputwrapper {
		flex: 1;
		max-width: 100% !important;
	}

	.new-style label {
		display: block;
		line-height: 18px;
	}
	.md-show {
		display: none;
	}

	@media (min-width: 768px) and (max-width: 1024px) {
		.form-container .email-row {
			flex-direction: column;
			gap: 20px;
		}

		.form-container .email-row .buttonwrapper {
			flex: 1;
			min-width: 100% !important;
			max-width: 100% !important;
		}

		.form-container .email-row .inputwrapper {
			flex: 1;
			max-width: 100% !important;
		}
	}

	@media (max-width: 500px) {
		.foldout-container {
			position: relative;
			top: -28px;
		}
		.md-hide {
			display: none;
		}
		.md-show {
			display: block;
		}
		.form-container .email-row {
			flex-direction: column;
			gap: 20px;
		}

		.form-container .email-row .buttonwrapper {
			flex: 1;
			min-width: 100%;
			max-width: 100%;
		}

		.form-container .email-row .inputwrapper {
			flex: 1;
			max-width: 100%;
		}

		label {
			display: block;
			line-height: 18px;
		}

		.signup-notice {
			text-align: center;
		}
	}
</style>
