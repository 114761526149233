import BoundRegistrationWidget from '../lib/BoundRegistrationWidget.svelte';

class CustomElementRegistrationWidget extends HTMLElement {
	#shadow: Element | ShadowRoot;
	#component: BoundRegistrationWidget | undefined;

	static get observedAttributes() {
		return ['staging', 'redirect', 'className'];
	}

	attributeChangedCallback(name: string, oldValue: string, newValue: string) {
		console.log('Custom square element attributes changed.', name, newValue);

		if (name === 'staging') {
			if (this.#component) {
				this.#component.$set({
					staging: !!newValue
				});
			}
		}
	}

	constructor() {
		super();
		this.#shadow = this.attachShadow({ mode: 'open' });
	}

	connectedCallback() {
		const staging = !!this.getAttribute('staging');
		const redirect = this.getAttribute('redirect') ?? '';
		const className = this.getAttribute('className') ?? '';

		const styleElement = document.createElement('style');
		styleElement.setAttribute('type', 'text/css');
		styleElement.textContent = `
			:host {
				display: block;
				width: 100%;
				max-width: 480px;
			}
		`;
		this.#shadow.appendChild(styleElement);

		this.#component = new BoundRegistrationWidget({
			target: this.#shadow,
			props: {
				staging,
				redirect,
				className
			}
		});
	}
}

if (!window.customElements.get('lingio-register-user')) {
	window.customElements.define('lingio-register-user', CustomElementRegistrationWidget);

	const styleElement = document.createElement('style');
	styleElement.setAttribute('type', 'text/css');
	styleElement.textContent = `
		lingio-register-user:focus {
			outline: none;
		}
	`;
	document.head.appendChild(styleElement);
} else {
	console.log(`Trying to define already defined 'lingio-register-user'`);
}
