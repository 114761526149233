<script lang="ts">
	export let error: boolean = false;
	export let visible: boolean = false;
	export let className: string = "";
</script>

<div class="foldout {className}" class:error class:visible>
	<div class="arrow">
		<svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_1855_1676)">
				<path d="M0.0449489 13.6114C0.432116 13.6857 0.520282 13.365 0.654449 13.1186C1.32145 11.8944 2.39478 11.3389 3.70578 11.249C4.62578 11.1864 5.55345 11.1747 6.47345 11.2216C7.29762 11.2646 7.53145 10.9126 7.46628 10.1264C7.17112 6.61013 6.15528 3.38327 3.90895 0.629686C3.77478 0.465409 3.56395 0.320689 3.62912 0.0116933C4.50312 -0.082179 5.30812 0.258108 6.11695 0.504523C11.5641 2.17467 15.8689 5.34285 18.6098 10.5254C18.8704 11.0182 19.1886 11.2255 19.7368 11.2177C20.8178 11.202 21.8988 11.2451 22.9798 11.2646C22.9798 12.2698 22.9453 13.2751 22.9913 14.2764C23.0181 14.8239 22.9261 14.996 22.3243 14.9882C18.7669 14.9491 15.2134 14.9687 11.6561 14.9687C8.09878 14.9687 4.54528 14.9374 0.987949 14.9921C0.167615 15.0039 -0.119884 14.8005 0.0372822 13.9908C0.0602822 13.8657 0.0372822 13.7327 0.0372822 13.6036L0.0449489 13.6114Z" fill="#F4F4F4"/>
			</g>
			<defs>
				<clipPath id="clip0_1855_1676">
					<rect width="23" height="15" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	</div>
	<div class="content">
		<slot />
	</div>
</div>

<style>
	.foldout {
		position: absolute;
		left: 0;
		top: 0;
		margin-left: 12px;
		opacity: 0;
		transform: translateY(5px);
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
		pointer-events: none;
	}

	.foldout.error .arrow path {
		fill: #F4F4F4;
	}
	.foldout.error .content {
		display: flex;
		align-items: center;
	}

	.foldout.error .content svg {
		margin-right: 5px;
	}

	.foldout .content {
		padding: 6px 15px;
		background-color: #f3f3f3;
		border-radius: 20px;
		box-shadow: none;
		font-size: 13px;
	}

	.foldout.error .content {
		background-color: #F4F4F4;
	}

	.arrow {
		position: absolute;
		left: 16px;
		top: -15px;
		width: 15px;
		height: 15px;
	}

	.foldout.visible {
		opacity: 1;
		transform: none;
		pointer-events: all;
		transition-delay: 100ms;
	}

	.foldout.msgbnew-style .content {
		background-color: transparent!important;
		line-height: 14px;
	}

	.foldout.msgbnew-style .arrow {
		display: none;
	}

	.foldout.msgbnew-style {
		display: flex;
		justify-content: center;
		width: 100%;
		margin: 0;
	}

	@media (max-width: 500px) {
		.foldout .content {
			line-height: 14px;
		}
	}
</style>
