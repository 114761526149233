<script context="module" lang="ts">
	declare let window: Window;
</script>

<script lang="ts">
	import { onMount } from 'svelte';

	import InnerRegistrationWidget from './InnerRegistrationWidget.svelte';
	import {
		createTrackingString,
		reportRegistrationEvent,
		trackRequestAdParameters
	} from './tracker.js';
	import type { SubmitEventPayload, WidgetState } from './types';
	import { decodeUrlParams } from './urlUtils';

	export let staging: boolean = false;
	export let redirect: string = '';
	export let className: string = '';

	let state: WidgetState = 'loading';
	let email: string | undefined;
	let terms: boolean = false;
	let anyError: boolean = false;
	let showTerms: boolean = false;
	let showError: boolean = false;

	async function callApi(email: string) {
		const tracking = await createTrackingString();

		const trackingData = decodeUrlParams(tracking);
		const sectorId: string | undefined = trackingData?.['landingsector'] ?? undefined;
		const jobRoleId: string | undefined = trackingData?.['landingrole'] ?? undefined;

		const body = JSON.stringify({ email, tracking, sectorId, jobRoleId });
		const url = staging
			? 'https://invite-service.staging.api.lingio.com/registrations'
			: 'https://invite-service.production.api.lingio.com/registrations';

		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body
		});

		if (!response.ok) {
			console.warn('Api call was not successful', response.status, response.statusText);
			throw response.status;
		}

		const result = response.json();
		return result;
	}

	function signup(event: CustomEvent<SubmitEventPayload>) {
		showError = false;

		callApi(event.detail.email)
			.then(async (result) => {
				state = 'submitted';
				const url = redirect ? redirect : 'https://www.lingio.com/en/global/register/success';
				console.log('Sign up successful', result, url);
				await reportRegistrationEvent();
				setTimeout(() => {
					window.location = url;
				}, 1000);
			})
			.catch((errorcode) => {
				console.warn('Api call failed', errorcode);

				if (errorcode === 409) {
					// 409 - Email (currently partner) already registered
					state = 'email-already-exists';
				} else if (errorcode === 401) {
					// 401 - Email is not allowed
					state = 'private-email';
				} else if (errorcode === 403) {
					// 403 - Forbidden, probably a blocked IP range
					state = 'blocked';
				} else {
					state = 'invalid-email';
				}
				setTimeout(() => {
					showError = true;
				});
			});
	}

	function back() {
		showError = false;
		setTimeout(() => {
			state = 'default';
		}, 200);
	}

	$: anyError = state !== 'default' && state !== 'submitted';
	$: showTerms = !anyError && (email?.length ?? 0) > 3;

	onMount(() => {
		setTimeout(() => {
			trackRequestAdParameters();

			state = 'default';
		}, 300);
	});
</script>

<InnerRegistrationWidget
	bind:email
	bind:terms
	{state}
	{className}
	{showTerms}
	{showError}
	on:signup={signup}
	on:back={back}
/>
